import PopupModal from '$lib/components/_page/popup-modal.svelte'

export function showPopupModal() {
    let localStorageKey = 'new-feature-verb-conjugation'
    const hasSeen = localStorage.getItem(localStorageKey)
    if (import.meta.env.DEV || hasSeen) return

    const popupModal = new PopupModal({
        target: document.getElementById('page') ?? document.body
    })

    popupModal.$on('close', () => {
        localStorage.setItem(localStorageKey, 'seen')
        popupModal.$destroy()
    })
}