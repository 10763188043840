<!--suppress HtmlUnknownAttribute -->
<script>
    export let viewBox = "0 0 20 20",
        width = null,
        height = null,
        fill = null,
        background = null,
        style = null,
        right = '1rem',
        top = '1rem'

    export let close = false

</script>

<div id="close-btn" on:click
     style:right
     style:top
>
    {#if close}
        <span>Close</span>
    {/if}
    <svg xmlns="http://www.w3.org/2000/svg" {width} {height} {viewBox} {fill} style:background {style}>
        <title> close </title>
        <path d="M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z"/>
        <path d="M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z"/>
    </svg>

</div>


<!--suppress CssUnresolvedCustomProperty -->
<style>

    #close-btn {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        z-index: var(--z-index-close-x);
    }

    span {
        margin-right: .4rem;
        font-size: .95rem;
        line-height: 1;
    }

    svg:hover {
        fill: var(--color-main);
    }

    svg:focus {
        outline: none;
    }


</style>