<script>
    import {onMount} from "svelte";
    import IconClose from "$lib/components/assets/IconClose.svelte";
    import {clickOutside} from "$lib/utils/click-outside.js";
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    let dialog

    onMount(() => {
        dialog.showModal()
    })

    function closeDialog() {
        dialog.close()
        dispatch('close');
    }
</script>

<dialog
        bind:this={dialog}
        on:close={closeDialog}
>
    <div
            use:clickOutside
            on:outclick={closeDialog}
    >
        <IconClose width="25" height="25"
                   fill="var(--color-Verb)"
                   on:click={closeDialog}
        />
        <h1>
            New Setting 🎉
        </h1>
        <p>
            Show <b>verb conjugation information</b> on flashcards
        </p>
        <img src="/new-feature-verb-conjugation.png" alt="New feature: verb conjugation">
        <div id="popup-modal-buttons">
            <a href="/settings" class="link-button"
               on:click={closeDialog}
            >
                Enable Now
            </a>
            <button
                    on:click={closeDialog}
            >
                No thanks
            </button>
        </div>
    </div>
</dialog>

<style>
    dialog {
        padding: 3rem 2.6rem 1.6rem 2.6rem;
        border: none;
        border-radius: var(--border-radius);
        text-align: center;
        color: inherit;
    }

    dialog > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    dialog::backdrop {
        background: rgba(0, 0, 0, 0.25);
    }

    h1 {
        margin: 0 0 .6rem 0;
    }

    p {
        font-size: .95rem;
        max-width: 28ch;
        margin: 0 0 1.2rem 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    #popup-modal-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.4rem;
    }

    button {
        background: none;
        font-size: .9rem;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

    a:focus,
    button:focus {
        outline: none;
    }

    @media (max-width: 1000px) {
        dialog {
            padding: 2.6rem 1.2rem 1.6rem 1.2rem;
        }
    }
</style>